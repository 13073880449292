import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'courageConnects';

  list: any[] = [];
  constructor() {
    this.list = [
      {
        name: 'National Suicide Prevention Lifeline',
        img: 'assets/ribbin-128.png',
        url: 'tel:1-800-273-8255',
        bgColor: 'linear-gradient(135deg, rgb(59 91 112) 10%, rgb(149, 153, 226) 100%)'
      },  
      {
        name: 'National Domestic Violence Hotline',
        img: 'assets/img-2.png',
        url: 'tel:18007997233',
        bgColor: 'linear-gradient(251deg, rgb(191, 209, 233) 10.4%, rgb(16, 72, 144) 87.7%)'
      },
      {
        name: 'Crisis Text Line',
        img: 'assets/img-3.png',
        url: 'sms:741741',
        bgColor: 'linear-gradient(135deg, rgb(151, 171, 255) 10%, rgb(18, 53, 151) 100%)'
      }
    ]
  }

  buttonClicked(ele) {
    console.log('ele: ', ele);
    window.location.href = ele.url
  }

  openLink() {
    window.open("https://high5.id/cchp")
  }
}
