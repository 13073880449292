<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img src="assets/CC-Logo.png" width="100" (click)="openLink()">
</div>

<div id="backgroundImg" class="backgroundImg">

  <div style="width: 100%;text-align: center;">
    <ng-container *ngFor="let ele of list;let i = index">
      <div *ngIf="i == 0 || i == 1" class="div-margin-bottom" style="text-align: center;" (click)="buttonClicked(ele)" [ngStyle]="{'background-image': ele.bgColor}">
        <div class="cardbox">
          <div class="icon-img d-flex justify-content-center">
            <img class="button-icon" [src]="ele.img" />
          </div>
          <div class="textcontent text-center">
            <p nz-title class="div-text mb-0 regular-font-with-family">{{ ele.name }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="i == 2" class="div-margin-bottom" style="text-align: center;" (click)="buttonClicked(ele)" [ngStyle]="{'background-image': ele.bgColor}">
        <div class="cardbox">
          <div class="icon-img d-flex justify-content-center">
            <img class="button-icon" [src]="ele.img" />
          </div>
          <div class="textcontent text-center">
            <p nz-title class="div-text mb-0 regular-font-with-family">{{ ele.name }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div style="text-align: center;position: absolute; bottom: 15px; width: 100%;margin-top: 15px;">
    <a href="https://high5.id/cchp" style="text-decoration: none;color: rgba(0, 0, 0, 0.85);" class="regular-font-with-family" target="_blank">
      Powered by High5.ID
    </a>
</div>
</div>

<router-outlet></router-outlet>